/* eslint-disable import/no-mutable-exports */
import type { IAppUtilities as IAppUtilitiesCore } from '@pixcap/ui-core/modules/appUtilities';
import { AppUtilities as AppUtilitiesCore, setAppUtilities } from '@pixcap/ui-core/modules/appUtilities';
import { AppServices } from '@/services';
import type { IAppServices } from '@/services';
import { AppWidgets } from '@/widgets';
import type { IAppWidgets } from '@/widgets';
import { Store } from 'vuex/types/index';

export type IAppUtilities = IAppUtilitiesCore<IAppServices, IAppWidgets>;

class AppUtilities extends AppUtilitiesCore implements IAppUtilities {
	static lastAppUtilities: IAppUtilities;
}

let appUtilities: IAppUtilities = null;

export function appUtilitiesFactory(context: { store: Store<any> }) {
	if (AppUtilities.lastAppUtilities) {
		appUtilities = AppUtilities.lastAppUtilities;
		return;
	}
	const appUtilitiesParams = new AppUtilities();
	AppUtilities.lastAppUtilities = appUtilitiesParams;
	appUtilitiesParams.$services = new AppServices(context);
	appUtilitiesParams.$widgets = new AppWidgets();
	setAppUtilities(appUtilitiesParams);
	appUtilities = appUtilitiesParams;
}

export { appUtilities };
