import { mutationsWrapper as UserMutations } from '@pixcap/ui-core/store/user';

export default function (context) {
	if (process.server) {
		let viewerCountry = context.req.headers['cloudfront-viewer-country'];
		const overrideViewerCountry = process.env.OVERRIDE_VIEWER_COUNTRY;
		if (overrideViewerCountry && overrideViewerCountry != '') viewerCountry = overrideViewerCountry;
		UserMutations.setUserCountry(context.store, viewerCountry);
	}
}
