import { SortingRenderersOptions } from '@pixcap/ui-core/constants/community.constants';
import { PaginationBaseType } from '@pixcap/ui-core/models/store/app.interface';

export type CommunitySortBy = SortingRenderersOptions;

export enum COMMUNITY_TAB {
	'3D_SCENES' = '3d-scenes',
	AI_SCENES = 'ai-scenes',
}

export enum COMMUNITY_RESOURCE_TYPE {
	'3D_SCENES' = '3D',
	AI_SCENES = 'AI',
}

export interface CommunityPayload {
	page: number;
	pageSize: number;
	sortBy?: CommunitySortBy;
	totalPages?: number;
	totalItems?: number;
	refresh?: boolean;
	isFetchRelated?: boolean;
	search?: string;
	exportType?: string;
	ignoreIds?: string[];
}

export interface CommunitySliderPayload {
	pageSize: number;
}

export interface CommunityThumbnail {
	fileId: string;
	variantId: string;
	fileUrl: string;
}

export interface CommunityItem {
	jobId: string;
	userId: string;
	userName: string;
	prompt: string;
	likes: number;
	liked: boolean;
	slug: string | null;
	templateId: string | null;
	isFeatured: boolean;
	userAvatar: string;
	original: string;
	fileName?: string;
	exportType?: COMMUNITY_RESOURCE_TYPE;
	animation: boolean;
	animationDuration: number;
	images: CommunityThumbnail[];
}

export interface CommunitySliderItem {
	fileId: string;
	fileUrl: string;
	prompt: string;
	renderId: string;
	slug: string;
	variantId: string;
}

export interface CommunitySlider {
	totalPages: number;
	totalItems: number;
	page: number;
	size: number;
	content: CommunitySliderItem[];
}

export interface ICommunityState {
	communityItems: PaginationBaseType<CommunityItem>;
	communityItemsSortBy: CommunitySortBy;
	communityItem: CommunityItem;
	communityRelatedItemList: PaginationBaseType<CommunityItem>;
	communityItemsSlider: CommunitySlider;
	communityItemInteracting: null;

	isFetchingCommunityItems: boolean;
	isFetchingCommunityItemDetails: boolean;
	isFetchingCommunitySlider: boolean;
	isFetchingCommunityRelatedModels: boolean;
	isInteractingCommunityItem: boolean;

	selectedCommunityItemSlug: string;

	activeCommunityTab: string;
}

export interface CommunityResponse {
	content: CommunityItem[];
	page: number;
	size: number;
	totalItems: number;
	totalPages: number;
}

export enum TargetDataKey {
	communityItems = 'communityItems',
	communityItem = 'communityItem',
	communityRelatedItemList = 'communityRelatedItemList',
}

export interface CommunityItemPayload {
	jobId: string;
	targetDataKey: TargetDataKey;
	isLiked: boolean;
}

export enum MutationTypes {
	SET_COMMUNITY_ITEMS = 'SET_COMMUNITY_ITEMS',
	SET_IS_FETCHING_COMMUNITY_ITEMS = 'SET_IS_FETCHING_COMMUNITY_ITEMS',
	RESET_COMMUNITY_ITEMS = 'RESET_COMMUNITY_ITEMS',

	SET_COMMUNITY_RELATED_ITEM_LIST = 'SET_COMMUNITY_RELATED_ITEM_LIST',
	SET_IS_FETCHING_COMMUNITY_RELATED_MODELS = 'SET_IS_FETCHING_COMMUNITY_RELATED_MODELS',
	RESET_COMMUNITY_RELATED_ITEM_LIST = 'RESET_COMMUNITY_RELATED_ITEM_LIST',

	SET_COMMUNITY_ITEM = 'SET_COMMUNITY_ITEM',
	SET_IS_FETCHING_COMMUNITY_ITEM_DETAILS = 'SET_IS_FETCHING_COMMUNITY_ITEM_DETAILS',

	SET_COMMUNITY_SLIDER = 'SET_COMMUNITY_SLIDER',
	SET_COMMUNITY_SLIDER_LOADING = 'SET_COMMUNITY_SLIDER_LOADING',

	SET_COMMUNITY_ITEM_INTERACTING_LOADING = 'SET_COMMUNITY_ITEM_INTERACTING_LOADING',
	UPDATE_COMMUNITY_ITEMS_BY_INTERACTING = 'UPDATE_COMMUNITY_ITEMS_BY_INTERACTING',

	SET_COMMUNITY_SORT_BY = 'SET_COMMUNITY_SORT_BY',

	SET_SELECTED_COMMUNITY_ITEM_SLUG = 'SET_SELECTED_COMMUNITY_ITEM_SLUG',

	SET_ACTIVE_COMMUNITY_TAB = 'SET_ACTIVE_COMMUNITY_TAB',
}

export enum ActionTypes {
	GET_LIST_COMMUNITY_ITEMS = 'GET_LIST_COMMUNITY_ITEMS',
	GET_COMMUNITY_ITEM = 'GET_COMMUNITY_ITEM',
	INTERACTING_COMMUNITY_ITEM = 'INTERACTING_COMMUNITY_ITEM',
	GET_LIST_COMMUNITY_ITEMS_SLIDER = 'GET_LIST_COMMUNITY_ITEMS_SLIDER',
}

export const NAMESPACE = 'community';
