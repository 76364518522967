export const NAMESPACE = 'blog';

export type StrapiMediaFormat = {
	name: string;
	mime: string;
	width: number;
	height: number;
	url: string;
};
export type StrapiMedia = {
	id: number;
	attributes: {
		name: string;
		createdAt: Date;
		updatedAt: Date;
		caption: string;
		width: number;
		height: number;
		url: string;
		mime: string;
		size: number;
		formats: {
			thumbnail: StrapiMediaFormat;
			small: StrapiMediaFormat;
			medium: StrapiMediaFormat;
			large: StrapiMediaFormat;
		};
	};
};

export type StrapiSEO = {
	metaTitle: string;
	metaDescription: string;
	keywords: string;
	metaRobots: string;
	structuredData: string;
	metaViewport: string;
	canonicalURL: string;
};

export type StrapiLocalization = {
	attributes: {
		locale: string;
		slug: string;
		name?: string;
	};
};

export type StrapiCategory = {
	id: number;
	attributes: {
		name: string;
		slug: string;
		createdAt: Date;
		updatedAt: Date;
		locale: string;
		seo?: StrapiSEO;
		localizations: {
			data: StrapiLocalization[]; // list of other available locales of this article
		};
	};
};

export type StrapiArticle = {
	id: number;
	attributes: {
		title: string;
		description: string;
		createdAt: string;
		updatedAt: string;
		publishedAt: string; // 2024-05-30T12:11:58.695Z
		slug: string;
		locale: string; // 'en' | 'es' |
		content: string;
		localizations: {
			data: StrapiLocalization[]; // list of other available locales of this article
		};
		thumbnail: { data: StrapiMedia };
		thumbnail_alt_text?: string;
		category?: { data: StrapiCategory };
		seo?: StrapiSEO;
	};
};

export type StrapiPaginationMeta = {
	page: number;
	pageCount: number;
	pageSize: number;
	total: number;
};

export type PixcapBlogCategory = {
	name: string;
	slug: string;
	seo?: StrapiSEO;
	otherAvailableLocales: { name: string; locale: string; slug: string }[];
};

export type PixcapArticle = {
	title: string;
	description: string;
	largeThumbnailUrl: string;
	thumbnailUrl: string;
	thumbnailAltText: string;
	content: string;
	category?: PixcapBlogCategory;
	slug: string;
	publishedAt: Date;
	otherAvailableLocales: { locale: string; slug: string }[];
	seo?: StrapiSEO;
};

export enum MutationTypes {
	SET_BLOG_LOCALE = 'SET_BLOG_LOCALE',
	GET_ARTICLES = 'GET_ARTICLES',
	SET_ARTICLES = 'SET_ARTICLES',
	SET_IS_LOADING_ARTICLE = 'SET_IS_LOADING_ARTICLE',
}

export enum ActionTypes {
	GET_ARTICLES = 'GET_ARTICLES',
	GET_RELATED_ARTICLES = 'GET_RELATED_ARTICLES',
	GET_ARTICLES_BY_CATEGORY = 'GET_ARTICLES_BY_CATEGORY',
	GET_ARTICLE_BY_SLUG = 'GET_ARTICLE_BY_SLUG',
	GET_CATEGORIES = 'GET_CATEGORIES',
	GET_CATEGORY_BY_SLUG = 'GET_CATEGORY_BY_SLUG',
}

export interface IBlog {
	locale: string;
}
