export interface IPreviewerState {
	previewImage?: { url: string, isVideo: boolean };
	previewImageTitle?: string;
	previewImagePurpose?: string;
}

export const NAMESPACE = 'preview';

export enum MutationTypes {
	SET_SHOW_IMAGE_PREVIEW_IMAGES = 'SET_SHOW_IMAGE_PREVIEW_IMAGES',
}
