
	import { Component, Mixins, Provide, ProvideReactive, Vue } from 'vue-property-decorator';
	import { mutationsWrapper as AppMutations } from '@pixcap/ui-core/store/app';
	import BackToTopButton from '@/components/pixcap-library/shared/BackToTopButton.vue';
	import BlogHeader from '@/components/layouts/blog/BlogHeader.vue';
	import BlogFooter from '@/components/layouts/blog/BlogFooter.vue';
	import { BACK_TO_TOP_SCROLL_OFFSET } from '@/constants/app.constants';
	import { checkScrollToButtom } from '@/utils/scrollUtils';
	import { CONTEXT_PROVIDER_TYPES } from '@pixcap/ui-core/models/widgets/contextProvider.interface';
	import BlogMixin from '@/pages/blog/BlogMixin';
	import { setupBaseMetaTags } from '@/utils/seo';
	import logger from '@pixcap/ui-core/helpers/logger';
	import { DEFAULT_BLOG_LOCALE } from '@/constants/blog.constants';
	import { mapState } from 'vuex';
	import { IBlog, NAMESPACE as BLOG_NAMESPACE } from '@/models/store/blog.interface';

	@Component({
		components: { BlogFooter, BlogHeader, BackToTopButton },
		layout: 'empty',
		name: 'Blog',
	})
	export default class PixcapBlog extends Mixins(BlogMixin) {
		_handleWindowVisibilityChange;
		_handleScreenSize;

		@ProvideReactive() isMobileScreen: boolean = false;
		@ProvideReactive() isTabletScreen: boolean = false;
		@ProvideReactive() isDesktopScreen: boolean = true;
		@ProvideReactive() isScrolled = false;
		@Provide()
		handleScrollBack(isSmoothBehavior = false) {
			if (process.client && this.$refs?.refMainLayout) {
				const $el = this.$refs.refMainLayout as HTMLElement;
				const behavior = (isSmoothBehavior ? 'smooth' : 'instant') as any;
				$el.scrollTo({ top: 0, behavior });
			}
		}

		handleScreenSize() {
			if (window.innerWidth <= 743) {
				this.isMobileScreen = true;
				this.isTabletScreen = false;
				this.isDesktopScreen = false;
			} else if (window.innerWidth <= 1024) {
				this.isMobileScreen = false;
				this.isTabletScreen = true;
				this.isDesktopScreen = false;
			} else {
				this.isMobileScreen = false;
				this.isTabletScreen = false;
				this.isDesktopScreen = true;
			}
		}

		handleWindowVisibilityChange() {
			const isVisible = document.visibilityState === 'visible';
			AppMutations.setIsAppWindowActive(this.$store, isVisible);
		}

		handleScroll() {
			const $el = this.$refs.refMainLayout as HTMLElement;
			const scrollTop = $el.scrollTop;
			if (scrollTop > BACK_TO_TOP_SCROLL_OFFSET) {
				if (!this.isScrolled) this.isScrolled = true;
			} else if (this.isScrolled) this.isScrolled = false;
			const scrollToBottom = checkScrollToButtom($el, 400);
			if (scrollToBottom) this.emitLibraryScrollInjections();
		}

		emitLibraryScrollInjections() {
			const { contextProvider } = this.$pixcap.$widgets;
			contextProvider.emitProviderInjections(CONTEXT_PROVIDER_TYPES.DASHBOARD_LAYOUT);
		}

		created() {
			this._handleWindowVisibilityChange = this.handleWindowVisibilityChange.bind(this);
			this._handleScreenSize = this.handleScreenSize.bind(this);
		}

		mounted() {
			logger.log('Deployed from: ', process.env.BRANCH_ORIGIN);
		}
	}
