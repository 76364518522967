import { Vue, Component } from 'vue-property-decorator';
import { mapState } from 'vuex';
import { mutationsWrapper as BlogMutations } from '@/store/blog/wrapper';
import { AVAILABLE_BLOG_LOCALE_ROUTE_PARAMS, DEFAULT_BLOG_LOCALE } from '@/constants/blog.constants';
import { IBlog, NAMESPACE as BLOG_NAMESPACE } from '@/models/store/blog.interface';

@Component({
	name: 'BlogMixin',
	computed: {
		...mapState(BLOG_NAMESPACE, {
			locale: (state: IBlog) => state.locale,
		}),
	},
})
export default class BlogMixin extends Vue {
	locale: IBlog['locale'];

	created() {
		if (AVAILABLE_BLOG_LOCALE_ROUTE_PARAMS.includes(this.$route.params.locale)) {
			BlogMutations.setBlogLocale(this.$store, this.$route.params.locale);
		} else {
			BlogMutations.setBlogLocale(this.$store, null);
		}
	}
}
