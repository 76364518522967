export enum ProjectTypes {
	ANIMATED = 'animated',
	STATIC = 'static',
}

export enum SharedProjectActions {
	REMOVED = 'REMOVED',
	ADDED = 'ADDED',
}

export const INITIAL_EDITOR_STATE_KEY = 'initialiseWith';

export enum SubmissionFileTypes {
	ALL_FILES = 'ALL_ASSETS',
	STATIC_MOCKUP_TEMPLATES = 'STATIC_MOCKUP_TEMPLATES',
	ANIMATED_MOCKUP_TEMPLATES = 'ANIMATED_MOCKUP_TEMPLATES',
	DESIGN_TEMPLATES = 'DESIGN_TEMPLATES',
	ANIMATED_DESIGN_TEMPLATES = 'ANIMATED_DESIGN_TEMPLATES',
	ANIMATED_MODELS = 'ANIMATED_MODELS',
	STATIC_MODELS = 'STATIC_MODELS',
	CHARACTERS = 'CHARACTERS',
	FRAMES = 'FRAMES',
	TEXT = 'TEXT',
	TEXT_SHAPES = 'TEXT_SHAPES',
	SHAPES = 'SHAPES',
	POSES = 'POSES',
}

export interface ProjectItem {
	projectId: string;
	projectTitle: string;
	updatedAt: string;
	thumbnailUrl?: string;
	animation?: boolean;
	animationDuration?: number;
	itemPreviewUrl?: string;
}

export interface ProjectState {
	projects: {
		listOfProjects: ProjectItem[];
		currentPage: number;
		totalPages: number;
		pageSize: number;
	};
	sharedProjects: {
		listOfProjects: ProjectItem[];
		currentPage: number;
		totalPages: number;
		pageSize: number;
	};
	searchQuery: string;
	isLoading: boolean;
	isLoadingShared: boolean;
	projectsToDuplicate: { [projectId: string]: boolean };
}

export enum MutationTypes {
	UPDATE_PROJECTS_LIST = 'UPDATE_PROJECTS_LIST',
	RESET_PROJECTS_LIST = 'RESET_PROJECTS_LIST',
	SET_LOADING_STATUS = 'SET_LOADING_STATUS',
	UPDATE_SHARED_PROJECTS_LIST = 'UPDATE_SHARED_PROJECTS_LIST',
	RESET_SHARED_PROJECTS_LIST = 'RESET_SHARED_PROJECTS_LIST',
	SET_IS_LOADING_SHARED = 'SET_IS_LOADING_SHARED',
	APPEND_SHARED_PROJECT_TO_LIST = 'APPEND_SHARED_PROJECT_TO_LIST',
	REMOVE_SHARED_PROJECT_FROM_LIST = 'REMOVE_SHARED_PROJECT_FROM_LIST',
	UPDATE_PROJECT_TITLE = 'UPDATE_PROJECT_TITLE',
	SET_PROJECT_TO_DUPLICATE = 'SET_PROJECT_TO_DUPLICATE',
	TOGGLE_DELETE_PROJECT = 'TOGGLE_DELETE_PROJECT',
}

export enum ActionTypes {
	FETCH_PROJECTS_OF_USER = 'FETCH_PROJECTS_OF_USER',
	FETCH_SHARED_PROJECTS = 'FETCH_SHARED_PROJECTS',
	DELETE_PROJECT = 'DELETE_PROJECT',
	UPDATE_PROJECT_TITLE = 'UPDATE_PROJECT_TITLE',
	CREATE_PROJECT = 'CREATE_PROJECT',
	CLONE_PROJECT = 'CLONE_PROJECT',
	SUBSCRIBE_TO_SHARED_PROJECT = 'SUBSCRIBE_TO_SHARED_PROJECT',
	DUPLICATE_PROJECT_AS_SUBMISSION = 'DUPLICATE_PROJECT_AS_SUBMISSION',
}

export const NAMESPACE = 'project';
