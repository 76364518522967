import { PaginationBaseType } from '@pixcap/ui-core/models/store/app.interface';
import { PixcapLibraryPack } from '@/models/store/pixcapassets.interface';

export type ContributorInfo = {
	contributorId: string;
	avatarThumbnailUrl: string;
	displayName: string;
	contributorSlug: string;
	description?: string;
	interests: { linkTagId: string; tagName: string; type: string; url: string }[];
	websiteUrl?: string;
	behanceUrl?: string;
	dribbleUrl?: string;
	instagramUrl?: string;
	twitterUrl?: string;
	facebookUrl?: string;
};

export interface IContributorState {
	isFetchingContributorInfo: boolean;
	contributorInfo: ContributorInfo;
	contributorPacks: PaginationBaseType<PixcapLibraryPack>;
}

export const NAMESPACE = 'contributors';

export enum MutationTypes {
	SET_CONTRIBUTOR_INFO = 'SET_CONTRIBUTOR_INFO',
	SET_IS_FETCHING_CONTRIBUTOR_INFO = 'SET_IS_FETCHING_CONTRIBUTOR_INFO',
}

export enum ActionTypes {
	GET_CONTRIBUTOR_INFO = 'GET_CONTRIBUTOR_INFO',
}
