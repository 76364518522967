export enum HomepageSection {
	ICON = 'icon',
	ANIMATED_ICON = 'animated-icon',
	CHARACTER = 'character',
	DEVICE_MOCKUP = 'device-mockup',
	BRANDING_MOCKUP = 'branding-mockup',
}

export interface IOrganismState {
	shouldShowSearchInput: boolean;
	shouldCustomizeHeader: boolean;
	showTutorialModal: string;
	showPromotionHeader: boolean;

	shouldHighlightHeader: boolean;

	isSearchPageFilterExpanded: boolean;

	categoryCount: any;
	showAuthWall: boolean;
}

export const NAMESPACE = 'organism';

export enum MutationTypes {
	SET_SHOULD_SHOW_SEARCH_INPUT = 'SET_SHOULD_SHOW_SEARCH_INPUT',
	SET_SHOULD_CUSTOMIZE_HEADER = 'SET_SHOULD_CUSTOMIZE_HEADER',
	SET_SHOW_TUTORIAL_MODAL = 'SET_SHOW_TUTORIAL_MODAL',
	SET_SHOW_PROMOTION_HEADER = 'SET_SHOW_PROMOTION_HEADER',

	SET_CATEGORY_COUNT = 'SET_CATEGORY_COUNT',

	TOGGLE_SEARCH_PAGE_FILTER = 'TOGGLE_SEARCH_PAGE_FILTER',

	SET_SHOULD_HIGHLIGHT_HEADER = 'SET_SHOULD_HIGHLIGHT_HEADER',

	SET_SHOW_AUTH_WALL = 'SET_SHOW_AUTH_WALL',
}

export enum ActionTypes {
	GET_FOOTER_SEO = 'GET_FOOTER_SEO',
	GET_CATEGORY_FOOTER_COUNT = 'GET_CATEGORY_FOOTER_COUNT',
}
